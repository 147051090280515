.home {
    font-family: andale mono, monospace;
}

.HomeText {
    margin-left: 50%;
    text-align: center;
}

.HomeText h1 {
    margin-right: 25%;
}

.computer {
    max-width: 100%;
    height: auto;
    width: 40%;
    margin-top: 2%;
    margin-left: 2%;
    float: left;
}

.HomeText p {
    margin-top: 5%;
    width: 80%;
    position: relative;
    max-width: 30em;
    
    background-color: #fff;
    padding: 1.125em 1.5em;
    font-size: 1.1em;
    border-radius: 1rem;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
}
  
.HomeText p::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 14em; 
    border: 1vw solid transparent;
    border-top: none;
  
    border-bottom-color: #fff;
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}

.skills {
    position: relative;
    top: 10em;
    left: -22em;
    text-align: center;
}

.skills h2 {
    font-size: 2em;
}

.skills ul {
    margin-top: 3%;
    list-style: none;
    column-count: 3;
    column-gap: 8vw;
    font-size: 1.2vw;
    
    background-color: #fff;
    padding: 1.125vw 5vw;
    font-size: 1.1vw;
    border-radius: 1rem;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
}

.skills ul li {
    background-color: #fff;
}


.skills ul li::before {
    background-color: #fff;
    content: "";
    background-image: url("../../Assets/circle.gif");
    background-size: contain;
    display: inline-block;
    width: 1em;
    height: 1em;
    position: relative;
    top: 0.1rem;
    margin-right: 0.2rem;
}

