* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
 
.App {
  font-family: andale mono, monospace;
  color: #7a5555;
}

.App-header {
  display: table;
  clear: both;
}

.headshot {
  max-width: 100%;
  height: auto;
  width: 14%;
  border-radius: 50%;
  margin-top: 2%;
  margin-left: 2%;
  float: left;
}

.headerText {
  width: 50%;
  text-align: center;
  margin: auto;
  margin-top: 3%;
  font-size: 2vw;
}
 
.Tabs {
  width: 100%;
  height: 100%;
  font-size: 1.2vw;
  color: #7a5555;
  border-radius: 2rem;
  font-family: andale mono, monospace;
}
 
ul.nav {
  width: 60%;
  height: auto;
  margin: 0 auto 2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.1vw solid #7a5555;
  border-radius: 2rem;
  padding-left: 0px;
  @media (max-width: 768px) {
    width: 90%;
  }
  @media only screen and (max-device-width: 250px) {
    width: 100%;
  }
}
 
ul.nav li {
  width: 50%;
  padding: 2vw;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
}
 
ul.nav li:first-child {
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}
 
ul.nav li:last-child {
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}
 
ul.nav li:hover {
  background: #ca9d9d61;
}
 
ul.nav li.active {
  background: #d3bbbb;
}