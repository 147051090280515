.Contact body
{
  margin:0;
  padding:0;
  background:#ccc;
}
.Contact ul
{
  position:absolute;
  top:25vw;
  left:50%;
  transform:translate(-50%,-50%);
  margin:0;
  padding:0;
  display:flex;
}
.Contact ul li
{
  list-style:none;
  margin:0 5vw;
}
.Contact ul li a .fa
{
  font-size: 3vw;
  color:#262626;
  line-height:4vw;
  transition:.5s;
}
.Contact ul li a 
{
  position:relative;
  display:block;
  width:4vw;
  height:4vw;
  background:#fff;
  text-align:center;
  transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(0,0);
  transition:.5s;
  transition-timing-function: linear;
  box-shadow:-2vw 2vw 1vw rgba(0,0,0,.5);
}
.Contact ul li a:before
{
  content:'';
  position:absolute;
  top:0.8vw;
  left:-1.2vw;
  height:100%;
  width:1.5vw;
  background:#b1b1b1;
  transition: .5s;
  transform: rotate(0deg) skewY(-45deg);
}
.Contact ul li a:after
{
  content:'';
  position:absolute;
  bottom:-1.5vw;
  left:-0.5vw;
  height:1.5vw;
  width:95%;
  background:#b1b1b1;
  transition: .85s;
  transform: rotate(0deg) skewX(-45deg);
}
.Contact ul li a:hover
{
  transform: perspective(10vw) rotate(-30deg) skew(25deg) translate(2vw,-2vw);
  box-shadow:-5vw 5vw 5vw rgba(0,0,0,.5);
}
.Contact ul li:hover .fa
{
  color:#fff;
}

/* skype */
.Contact ul li:hover:nth-child(1) a
{
  background: #00AFF0;
}
.Contact ul li:hover:nth-child(1) a:before
{
  background: #166e9b;
}
.Contact ul li:hover:nth-child(1) a:after
{
  background: #3c8eb9;
}

/* github */
.Contact ul li:hover:nth-child(2) a
{
  background: #4b4e58;
}
.Contact ul li:hover:nth-child(2) a:before
{
  background: #28292a;
}
.Contact ul li:hover:nth-child(2) a:after
{
  background: #5c5f6d;
}
