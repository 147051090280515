
* {
    text-align: center;
}

.leftSec {
    width: 50%;
    height: 50vw;
    float:left;
    background-image: url("../../Assets/purdue.png");
    background-repeat: no-repeat;
    background-size: 40vw auto;
    background-position-y: 15vw;
    background-position-x: center;
}

.leftSec h1 {
    margin-top: 6vw;
    max-width: 100%;
    text-transform: uppercase;
    background-image: linear-gradient(
        -225deg,
        #0f0f10 0%,
        #FFD700 29%,
        #0f0f10 67%,
        #FFD700 100%
    );
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
        font-size: 3vw;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.leftSec h2 {
    margin-top: 2vw;
    color: black;
}
.leftSec h3 {
    margin-top: 2vw;
    color: black;
}

.leftSec p { 
    margin-top: 2vw;
    margin-left: 1vw;
    margin-right: 1vw;
    border: 0.5vw solid;
    padding: 1vw;
    border-image: repeating-linear-gradient(to bottom right, #000000, #FFD700, #000000, rgb(250, 238, 0)) 2vw;  
    background-color: #ffd90079;
    color: black;
}

.leftSec ul {
    list-style-type: none;
}

.leftSec li::before {
    content: "•"; 
    color: black;
    display: inline-block; 
    width: 1em;
    margin-left: -1em
}
  
.rightSec {
    width:50%; 
    height: 550px;
    float:left;
    color: black;
}

.rightSec h1 {
    margin-top: 6vw;
    max-width: 100%;
    text-transform: uppercase;
    background-image: linear-gradient(
        -225deg,
        #0f0f10 0%,
        #ffb300 29%,
        #0f0f10 67%,
        #ffb300 100%
    );
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
        font-size: 3vw;
}

.rightSec h2 {
    margin-top: 2vw;
}

.rightSec h3 {
    margin-top: 2vw;
}

.rightSec p{
    margin-top: 2vw;
}

.awsccp p {
    margin-top: 2vw;
}

.awsccp img{
    width: 30%;
    height: auto;
}
